import { SalePayment, PaymentMethod, CashRegisterAction } from "src/api/sales";
import {
  Afip,
  BBVA,
  CuentaDNI,
  Edenred,
  MercadoPago,
  Nave,
  Rappi,
} from "src/components/Icon";
import { GetNet } from "../components/Icon";
import { TransactionKind } from "src/api/registers";

export const paymentMethods = [
  {
    id: 0,
    name: "Descuento",
    type: PaymentMethod.Discount,
    identifier: "DISCOUNT",
    digital: true,
  },
  {
    id: 0.5,
    name: "Descuento por Cliente",
    type: PaymentMethod.CustomerDiscount,
    identifier: "DISCOUNT",
    digital: true,
  },
  {
    id: 1,
    name: "Tarjeta de Crédito",
    type: PaymentMethod.CreditCard,
    identifier: "CREDIT_CARD",
  },
  {
    id: 2,
    name: "Tarjeta de Débito",
    type: PaymentMethod.DebitCard,
    identifier: "DEBIT_CARD",
  },
  {
    id: 3,
    name: "Tarjeta Prepaga",
    type: PaymentMethod.PrepaidCard,
    identifier: "PREPAID_CARD",
  },
  { id: 4, name: "Efectivo", type: PaymentMethod.Cash, identifier: "CASH" },
  {
    id: 5,
    name: "Mercado Pago",
    type: PaymentMethod.MercadoPago,
    identifier: "MERCADO_PAGO",
  },
  {
    id: 6,
    name: "Edenred",
    type: PaymentMethod.Edenred,
    identifier: "EDENRED",
  },
  {
    id: 7,
    name: "Cuenta DNI",
    type: PaymentMethod.CuentaDNI,
    identifier: "CUENTA_DNI",
  },
  {
    id: 8,
    name: "Rappi Pay",
    type: PaymentMethod.RappiPay,
    identifier: "RAPPI_PAY",
  },
  {
    id: 9,
    name: "A convenir",
    type: PaymentMethod.Custom,
    identifier: "CUSTOM",
    digital: true,
  },
  {
    id: 10,
    name: "Billetera Tienda Nube",
    type: PaymentMethod.TiendaNubeWallet,
    identifier: "TIENDA_NUBE_WALLET",
    digital: true,
  },
  {
    id: 11,
    name: "Comisión",
    type: PaymentMethod.Fee,
    identifier: "FEE",
    digital: true,
  },
  {
    id: 12,
    name: "Nave",
    type: PaymentMethod.Nave,
    identifier: "NAVE",
  },
  {
    id: 13,
    name: "Openpay BBVA",
    type: PaymentMethod.OpenpayBBVA,
    identifier: "OPENPAY_BBVA",
  },
  {
    id: 14,
    name: "GetNet",
    type: PaymentMethod.GetNet,
    identifier: "GETNET",
  },
];

export const findPaymentMethod = (paymentMethod: PaymentMethod) =>
  paymentMethods.find((pm) => pm.type === paymentMethod);

export const parsePaymentMethods = (payments: SalePayment[]) => {
  const usedPayments = payments.reduce((prev: string[], curr: SalePayment) => {
    if (
      [
        PaymentMethod.Discount,
        PaymentMethod.CustomerDiscount,
        PaymentMethod.Fee,
      ].includes(curr.type)
    ) {
      return prev;
    }

    if (!prev.includes(curr.type)) {
      return [...prev, curr.type];
    }

    return prev;
  }, []);

  return usedPayments
    .map((payment) => findPaymentMethod(payment as PaymentMethod)?.name)
    .join(", ");
};

const MethodItem = {
  [PaymentMethod.Cash]: {
    icon: "banknotes",
    color: "success",
    title: "Efectivo",
  },
  [PaymentMethod.MercadoPago]: {
    CustomIcon: () => <MercadoPago size={24} />,
    color: "info",
    title: "Mercado Pago",
  },
  [PaymentMethod.DebitCard]: {
    icon: "credit-card",
    color: "warning",
    title: "Tarjeta de Débito",
  },
  [PaymentMethod.CreditCard]: {
    icon: "credit-card",
    color: "primary",
    title: "Tarjeta de Crédito",
  },
  [PaymentMethod.PrepaidCard]: {
    icon: "credit-card",
    color: "danger",
    title: "Tarjeta Prepaga",
  },
  [PaymentMethod.CuentaDNI]: {
    CustomIcon: () => <CuentaDNI size={24} />,
    icon: "credit-card",
    color: "dark",
    title: "Cuenta DNI",
  },
  [PaymentMethod.Edenred]: {
    CustomIcon: () => <Edenred size={24} />,
    color: "success",
    title: "Edenred",
  },
  [PaymentMethod.RappiPay]: {
    CustomIcon: () => <Rappi size={24} />,
    color: "warning",
    title: "Rappi Pay",
  },
  [PaymentMethod.IVA]: {
    CustomIcon: () => <Afip size={24} />,
    color: "primary",
    title: "IVA",
  },
  [PaymentMethod.Fee]: {
    icon: "fee",
    color: "secondary",
    title: "Comisión",
  },
  [PaymentMethod.Nave]: {
    CustomIcon: () => <Nave size={24} />,
    color: "success",
    title: "NAVE",
  },
  [PaymentMethod.OpenpayBBVA]: {
    CustomIcon: () => <BBVA size={24} />,
    color: "info",
    title: "Openpay BBVA",
  },
  [PaymentMethod.GetNet]: {
    CustomIcon: () => <GetNet size={24} />,
    color: "default",
    title: "GetNet",
  },
};

const TransactionKinds = {
  [TransactionKind.AddMoney]: {
    label: "Ingreso",
  },
  [TransactionKind.Sell]: {
    label: "Venta",
  },
  [TransactionKind.WithdrawMoney]: {
    label: "Egreso",
  },
};

export const parseMethodItem = (method: PaymentMethod) => MethodItem[method];

export const parseTransacionKind = (type: TransactionKind) =>
  TransactionKinds[type];

export const registerActions = [
  {
    id: 1,
    name: "Agregar dinero",
    type: CashRegisterAction.AddMoney,
  },
  {
    id: 2,
    name: "Quitar dinero",
    type: CashRegisterAction.WithdrawMoney,
  },
  {
    id: 3,
    name: "Venta",
    type: CashRegisterAction.Sell,
  },
];

export const findRegisterAction = (action: CashRegisterAction) =>
  registerActions.find((a) => a.type === action);
