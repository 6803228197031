import { gql } from "@apollo/client";
import { Product } from "./products";
import { User } from "./users";
import { Category } from "./categories";
import { Supplier } from "./suppliers";
import request from "./request";

export enum StockType {
  Unlimited = "UNLIMITED",
  Limited = "LIMITED",
  Mixed = "MIXED",
}

export type WarehouseStock = {
  id: number;
  warehouseId: number;
  cost: number;
  quantity: number;
  notes: string;
  retireId: number | null;

  product: Pick<Product, "id" | "name">;
  batch: {
    updatedAt: Date;
    user: User;
  };
  retire: {
    updatedAt: Date;
    user: User;
  };
};

export type Stock = {
  stock: number;
  cost: number;
  updatedAt: Date;
  productName: string;
  isLowStock: boolean;
  nextExpiresAt?: Date;
  product: Pick<Product, "id" | "name" | "barcode" | "price"> & {
    category: Pick<Category, "name">;
    supplier: Pick<Supplier, "name">;
  };
  items: WarehouseStock[];
};

export const LIST_STOCK = gql`
  query stock($filters: WarehouseStockFilters!) {
    data: stock(filters: $filters) {
      data {
        stock
        cost
        updatedAt
        productName
        isLowStock
        nextExpiresAt

        product {
          id
          name
          barcode
          price

          category {
            name
          }

          supplier {
            name
          }
        }

        items {
          id
          cost
          quantity
          notes
          retireId

          batch {
            updatedAt

            user {
              name
            }
          }

          retire {
            updatedAt

            user {
              name
            }
          }
        }
      }
      meta {
        total
        lastPage
      }
    }
  }
`;

export const GET_LOW_STOCK = gql`
  query lowStock($warehouseId: Int!) {
    data: lowStock(warehouseId: $warehouseId) {
      data {
        stock
        updatedAt
        productName

        product {
          id
          name
          barcode
          price
          stockAlert

          category {
            name
          }

          supplier {
            name
          }
        }
      }
      meta {
        total
        lastPage
      }
    }
  }
`;

export const FETCH_WAREHOUSE_STOCK = gql`
  query fetchWarehouseStock($warehouseId: Int!, $productId: Int!) {
    data: fetchWarehouseStock(
      warehouseId: $warehouseId
      productId: $productId
    ) {
      warehouseId
      productId
      data {
        id
        cost
        quantity
        notes
        retireId

        batch {
          updatedAt

          user {
            name
          }
        }

        retire {
          updatedAt

          user {
            name
          }
        }
      }
    }
  }
`;

export const uploadBaseStock = (body: any) =>
  request.post(`/imports/stock`, body, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
