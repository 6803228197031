import { useMutation, useQuery } from "@apollo/client";
import {
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CLoadingButton,
  CRow,
  CSmartTable,
} from "@coreui/react-pro";
import { Item } from "@coreui/react-pro/dist/esm/components/smart-table/types";
import { useState } from "react";
import { useLocation } from "react-router-dom";
import Api from "src/api";
import { AppLoader } from "src/components/Loader/Loader";
import { dateFormat } from "src/helpers/dates";
import { usePagination } from "src/hooks/pagination";
import { useAdminStore } from "src/store";
import { GraphQLMeta } from "src/types";
import yup from "src/helpers/validation";
import RetireList from "./components/RetireList";
import { Retire, RetireType } from "src/api/retires";
import { queryStringToObject } from "src/helpers/strings";
import SmartSelect from "src/components/SmartSelect";
import Pagination from "src/components/Pagination";

export const AddStockSchema = yup.object().shape({
  productId: yup.number().min(1).required(),
  cost: yup.number().min(0.1).required(),
  quantity: yup.number().integer().min(0.1).required(),
});

const parseRetireType = (type: RetireType) => {
  if (type === RetireType.Sale) {
    return "Venta";
  }

  if (type === RetireType.Move) {
    return "Movimiento";
  }

  return "Otro";
};

const InitialScreen = () => {
  const { state, search } = useLocation();
  const [currentRetireId, setCurrentRetireId] = useState<number>();
  const { hasPermission, currentCompany, user } = useAdminStore();
  const querySearch = { ...queryStringToObject(search), ...state };

  const { data: retires, refetch } = useQuery<GraphQLMeta<Retire>>(
    Api.Retires.LIST_RETIRES,
    {
      fetchPolicy: "no-cache",
      variables: {
        filters: {
          page: querySearch.page ? Number(querySearch.page) : 1,
          companyId: currentCompany?.id,
          warehouseId: querySearch.warehouseId,
          type:
            !querySearch.type || querySearch.type === "ALL"
              ? undefined
              : [querySearch.type],
        },
      },
    }
  );
  const [createRetire, { loading }] = useMutation(Api.Retires.CREATE_RETIRE, {
    onCompleted: ({ data }) => {
      setCurrentRetireId(data.id);
      refetch();
    },
  });

  const { page, pageChange, resetAndSearch } = usePagination(
    `stock/remove`,
    refetch
  );

  if (!retires?.data.data) {
    return <AppLoader />;
  }

  const retiresList = retires.data.data;

  if (currentRetireId) {
    return (
      <RetireList
        retireId={currentRetireId}
        warehouseId={state.warehouseId}
        goBack={() => {
          setCurrentRetireId(undefined);
          refetch();
        }}
      />
    );
  }

  let canCreateRetire = false;

  if (state.storeIds.length > 0) {
    canCreateRetire = state?.storeIds?.some((storeId: number) =>
      hasPermission("CREATE_RETIRE", { type: "store", id: storeId })
    );
  } else {
    canCreateRetire = hasPermission("CREATE_RETIRE", {
      type: "company",
      id: state?.companyId,
    });
  }

  return (
    <>
      <CCard>
        <CCardHeader>
          <CRow className="align-items-center justify-content-center">
            <CCol sm={6}>Egresos de {state.warehouseName}</CCol>
            <CCol sm={6} className="text-right d-flex justify-content-end">
              <CCol sm={3}>
                <SmartSelect
                  defaultValue={querySearch.type}
                  onChange={(e) => {
                    resetAndSearch({
                      warehouseId: Number(state?.warehouseId),
                      type: e === "ALL" ? [] : [e],
                    });
                  }}
                  name="type"
                  options={[
                    {
                      name: "Todos",
                      value: "ALL",
                    },
                    {
                      name: "Movimientos",
                      value: "MOVE",
                    },
                    {
                      name: "Otros",
                      value: "OTHER",
                    },
                    {
                      name: "Ventas",
                      value: "SALE",
                    },
                  ]}
                />
              </CCol>

              {canCreateRetire && (
                <CLoadingButton
                  color="primary"
                  loading={loading}
                  disabled={loading}
                  className="ml-3"
                  size="sm"
                  onClick={() => {
                    createRetire({
                      variables: {
                        input: {
                          companyId: currentCompany?.id,
                          userId: user?.id,
                          warehouseId: state?.warehouseId,
                        },
                      },
                    });
                  }}
                >
                  Crear nuevo egreso
                </CLoadingButton>
              )}
            </CCol>
          </CRow>
        </CCardHeader>
        <CCardBody>
          <CSmartTable
            itemsPerPage={20}
            items={retiresList ?? []}
            noItemsLabel="Aun no has egresado stock"
            columns={[
              { key: "id", label: "ID" },
              { key: "user", label: "Usuario" },
              { key: "warehouse", label: "Depósito" },
              { key: "type", label: "Tipo" },
              {
                key: "createdAt",
                label: "Fecha de Creación",
                _props: { className: "text-center" },
              },
              {
                key: "updatedAt",
                label: "Ultima actualización",
                _props: { className: "text-center" },
              },
              {
                key: "status",
                label: "Estado",
                _props: { className: "text-right" },
              },
            ]}
            scopedColumns={{
              user: (item: Retire) => (
                <td>
                  {item.user.name} {item.user.lastname}
                </td>
              ),
              warehouse: (item: Retire) => <td>{item.warehouse.name}</td>,
              type: (item: Retire) => <td>{parseRetireType(item.type)}</td>,
              createdAt: (item: Retire) => (
                <td className="text-center">
                  {dateFormat(item.createdAt, "dd/MM/yyyy HH:mm")}
                </td>
              ),
              updatedAt: (item: Retire) => (
                <td className="text-center">
                  {dateFormat(item.updatedAt, "dd/MM/yyyy HH:mm")}
                </td>
              ),
              status: (item: Retire) => (
                <td className="text-right">
                  {item.status === "OPEN" && "Abierto"}
                  {item.status === "FULFILLED" && "Retirado"}
                  {item.status === "CLOSED" && "Cerrado"}
                </td>
              ),
            }}
            tableProps={{
              striped: true,
            }}
            clickableRows
            onRowClick={(item: Item) => {
              setCurrentRetireId(item.id);
            }}
          />

          <Pagination meta={retires} page={page} pageChange={pageChange} />
        </CCardBody>
      </CCard>
    </>
  );
};

export default InitialScreen;
