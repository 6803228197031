import { initializeApp } from "firebase/app";
import { getDatabase, ref, onValue } from "firebase/database";

import { logEvent } from "./analytics";
import Config from "src/config";
import { AppVersion, Env } from "src/types";

export const getAdminVersion = () => {
  initializeApp(Config.firebaseConfig);

  const db = getDatabase();

  const reference = ref(db, "admin");

  onValue(reference, (snapshot) => {
    const value = snapshot.val();
    const adminVersion = value.version;

    let parsedCurrent = Number(AppVersion.replace(/\./g, "") ?? 0);
    const parsedNew = Number(adminVersion?.replace(/\./g, "") ?? 0);

    if (adminVersion.length > AppVersion.length) {
      parsedCurrent = Number(
        AppVersion.padEnd(adminVersion.length, "0").replace(/\./g, "")
      );
    }

    if (parsedNew > parsedCurrent) {
      alert(
        "Nueva actualización disponible. Se encuentra una nueva actualización en las tiendas de aplicaciones, te recomendamos actualizar la app para beneficiarte de las mejoras que hay en la misma."
      );
    }
  });
};

export const getMaintenanceStatus = (currentScreen: string) => {
  initializeApp(Config.firebaseConfig);

  const db = getDatabase();
  const platform = "admin";

  const reference = ref(db, "admin");

  onValue(reference, (snapshot) => {
    const value = snapshot.val();
    const adminVersion = value.version;

    if (value.maintenance === true && currentScreen !== "/#/maintenance") {
      if (Config.siteEnv !== Env.QA) {
        logEvent("maintenance.on", { platform });

        window.location.href = "/#/maintenance";
      } else {
        logEvent("maintenance.on", { platform });
      }
    }

    if (value.maintenance === false && currentScreen === "/#/maintenance") {
      if (Config.siteEnv !== Env.QA) {
        logEvent("maintenance.off", { platform });

        window.location.href = "/";
      } else {
        logEvent("maintenance.off", { platform });
      }
    }

    if (adminVersion !== AppVersion) {
      logEvent("admin.version.different", {
        adminVersion,
        admin: adminVersion,
      });
    }
  });
};
