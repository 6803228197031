import * as Afip from "./afip";
import * as AppData from "./app-data";
import * as Audit from "./audit";
import * as Auth from "./auth";
import * as BankPromotions from "./bankPromotions";
import * as Batches from "./batches";
import * as Categories from "./categories";
import * as Companies from "./companies";
import * as Customers from "./customers";
import * as Dashboard from "./dashboard";
import * as Excels from "./excels";
import * as MercadoLibre from "./mercado-libre";
import * as MercadoPago from "./mercado-pago";
import * as MercadoShops from "./mercado-shops";
import * as Orders from "./orders";
import * as Permissions from "./permissions";
import * as Plans from "./plans";
import * as PriceLists from "./price-lists";
import * as Products from "./products";
import * as Registers from "./registers";
import * as Reports from "./reports";
import * as Retires from "./retires";
import * as Sales from "./sales";
import * as Sectors from "./sectors";
import * as Sessions from "./sessions";
import * as Stock from "./stock";
import * as Stores from "./stores";
import * as Suppliers from "./suppliers";
import * as Support from "./support";
import * as TiendaNube from "./tienda-nube";
import * as Transactions from "./transactions";
import * as Users from "./users";
import * as Warehouses from "./warehouses";

const Api = {
  Afip,
  AppData,
  Audit,
  Auth,
  BankPromotions,
  Batches,
  Categories,
  Companies,
  Customers,
  Dashboard,
  Excels,
  MercadoLibre,
  MercadoPago,
  MercadoShops,
  Orders,
  Permissions,
  Plans,
  PriceLists,
  Products,
  Registers,
  Reports,
  Retires,
  Sales,
  Sectors,
  Sessions,
  Stock,
  Stores,
  Suppliers,
  Support,
  TiendaNube,
  Transactions,
  Users,
  Warehouses,
};

export default Api;
