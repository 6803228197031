import { useMutation, useQuery } from "@apollo/client";
import {
  CAlert,
  CCard,
  CCol,
  CFormCheck,
  CFormLabel,
  CFormSelect,
  CLoadingButton,
  CRow,
} from "@coreui/react-pro";
import { useFormik } from "formik";
import { Fragment, useEffect, useRef, useState } from "react";
import Api from "src/api";
import { AfipInvoiceType } from "src/api/afip";
import { Store, StoreConfig, StoreType } from "src/api/stores";
import { AppLoader } from "src/components/Loader/Loader";
import Toast, { ForwardedToastProps } from "src/components/Toast";
import { findPointOfSale } from "src/helpers/stores";
import { useAfipData } from "src/hooks/afip";
import { useAdminStore } from "src/store";
import { AfipData, GraphQLMeta } from "src/types";

const MonoInvoices = [11, 12, 13];
const RInvoices = [1, 2, 3, 6, 7, 8];

export const AfipStoreConfigRow = ({
  afipData,
  store,
}: {
  afipData: AfipData;
  store: Store;
}) => {
  const { hasPermission } = useAdminStore();
  const canEdit = hasPermission("UPDATE_STORE");
  const toastRef = useRef<ForwardedToastProps>(null);
  const [selected, setSelected] = useState<AfipInvoiceType[]>(
    store.config.invoiceTypes
  );

  const [mutation, { loading }] = useMutation(Api.Stores.UPDATE_CONFIG, {
    onCompleted: () => {
      toastRef.current?.show();
      window.location.reload();
    },
  });

  const formik = useFormik<StoreConfig>({
    initialValues: store.config,
    onSubmit: (values) => {
      if (!loading) {
        const input = {
          invoiceTypes: selected.map((item) => ({
            id: item.id,
            name: item.name,
            anonymous: item.anonymous ?? false,
          })),
          posId: values.posId ? Number(values.posId) : null,
        };

        mutation({
          variables: {
            id: store.id,
            input,
          },
        });
      }
    },
  });

  const handleChange = (e) => {
    const { value, checked, dataset } = e.currentTarget;

    setSelected((prev) => {
      if (!checked) {
        return prev.filter((item) => item.id !== Number(value));
      }

      const anonymous =
        afipData.invoiceTypes.find(
          (invoiceType) => invoiceType.id === Number(value)
        )?.anonymous ?? false;

      return [...prev, { id: Number(value), name: dataset.label, anonymous }];
    });
  };

  useEffect(() => {
    const currentPOS = afipData.pos.find(
      (pos) => Number(pos.id) === Number(formik.values.posId)
    );

    if (currentPOS?.type.includes("- Monotributo")) {
      setSelected(
        afipData.invoiceTypes.filter(({ id }) => MonoInvoices.includes(id))
      );
    }

    if (currentPOS?.type.includes("- Ri")) {
      setSelected(
        afipData.invoiceTypes.filter(({ id }) => RInvoices.includes(id))
      );
    }
  }, [afipData.invoiceTypes, afipData.pos, formik.values.posId]);

  useEffect(() => {
    if (formik.values.posId === null && store.config.posId !== null) {
      formik.setFieldValue("posId", Number(store.config.posId));
    }
  }, [formik, store.config.posId]);

  if (afipData.pos.length === 0) {
    return <AppLoader />;
  }

  const currentType = findPointOfSale(store.type);
  const StoreIcon = () => currentType?.icon;

  return (
    <div className="position-relative p-2">
      <CRow>
        <div className="flex">
          <StoreIcon />
          <h5 className="ml-2">{store.name}</h5>
        </div>
      </CRow>
      <CRow>
        <CCol md="4">
          <CFormSelect
            floatingLabel="Punto de Venta"
            defaultValue={store.config.posId}
            name="posId"
            onChange={formik.handleChange}
          >
            <option value="0">Sin Punto de Venta</option>
            {afipData.pos.map((item) => (
              <option key={item.id} value={item.id}>
                {item.id} - {item.type}
              </option>
            ))}
          </CFormSelect>
        </CCol>
      </CRow>
      <CRow className="mt-3">
        <CFormLabel>Tipo de Factura</CFormLabel>
        {afipData.invoiceTypes.map((invoiceType) => (
          <CCol md="4" key={invoiceType.id}>
            <CFormCheck
              value={invoiceType.id}
              id={`invoice-type-${invoiceType.id}`}
              label={invoiceType.name}
              data-label={invoiceType.name}
              onChange={handleChange}
              defaultChecked={
                !!selected.find(({ id }) => invoiceType.id === id)
              }
            />
          </CCol>
        ))}
      </CRow>

      <Toast
        color="primary"
        autohide
        visible={false}
        ref={toastRef}
        text="Configuración actualizada"
      />

      <CRow className="mt-3">
        {!canEdit ? (
          <CCol md="12">
            <CAlert color="danger" className="px-3 py-1 m-0 text-center">
              No tienes permisos para editar estas configuraciones
            </CAlert>
          </CCol>
        ) : (
          <CCol md="12" className="text-right">
            <CLoadingButton
              size="sm"
              loading={loading}
              color="primary"
              disabled={loading}
              onClick={() => formik.handleSubmit()}
            >
              Guardar
            </CLoadingButton>
          </CCol>
        )}
      </CRow>
    </div>
  );
};

const AfipConnected = ({
  companyId,
  storeId,
  type,
}: {
  companyId: number;
  storeId?: number;
  type?: StoreType;
}) => {
  const { afipData, afipError } = useAfipData(companyId, storeId);

  const { data: storesList, error: storeError } = useQuery<GraphQLMeta<Store>>(
    Api.Stores.LIST_STORES,
    {
      variables: {
        filters: {
          companyId,
        },
      },
    }
  );

  if (storeError || afipError) {
    return null;
  }

  if (!storesList?.data?.data) {
    return <AppLoader />;
  }

  const stores = storesList.data.data.filter(({ id, type: storeType }) => {
    if (storeId) {
      if (type) {
        return storeType === type && storeId === id;
      }

      return storeId === id;
    }

    if (type) {
      return storeType === type;
    }

    return true;
  });

  if (!afipData.invoiceTypes) {
    return <AppLoader />;
  }

  return (
    <>
      <CRow>
        <CCol xl={12}>
          <CCard>
            {stores.map((store, index) => (
              <Fragment key={store.id}>
                {stores.length > 1 && index > 0 && <hr />}
                <AfipStoreConfigRow afipData={afipData} store={store} />
              </Fragment>
            ))}
          </CCard>
        </CCol>
      </CRow>
    </>
  );
};

export default AfipConnected;
