import { useContext } from "react";
import { User } from "src/api/users";
import { AuthContext } from "../contexts/Auth";
import { useAdminStore } from "src/store";
import { useMutation } from "@apollo/client";
import Api from "src/api";

export const useAuth = (): {
  isLogged: boolean;
  user: User | null;
} => {
  const { user = null, isLogged } = useContext(AuthContext);

  return {
    isLogged,
    user,
  };
};

export const useRefreshUser = () => {
  const { setUser } = useAdminStore();
  const { onLogout } = useAuthActions();

  const [refreshMutation, { loading }] = useMutation(Api.Auth.REFRESH_USER, {
    onCompleted: ({ user: response }) => {
      setUser(response);
    },
    onError: () => {
      onLogout?.();
    },
  });

  return {
    refreshMutation,
    refreshing: loading,
  };
};

export const useAuthActions = () => {
  const { hasPermission, onLogin, onLogout } = useContext(AuthContext);

  return {
    hasPermission,
    onLogin,
    onLogout,
  };
};
