import { gql } from "@apollo/client";
import { Store } from "./stores";
import { Company } from "./companies";

export type Warehouse = {
  id: number;
  companyId: number;
  name: string;
  streetName: string;
  streetNumber: string;
  phone: string;
  notes: string;
  company: Pick<Company, "id" | "name">;
  stores: Pick<Store, "id" | "name">[];
};

export type WarehouseCreate = Pick<
  Warehouse,
  "companyId" | "name" | "streetName" | "streetNumber" | "phone" | "notes"
> & {
  stores: number[];
};

export type WarehouseUpdate = WarehouseCreate;

export const LIST_WAREHOUSES = gql`
  query warehouses($filters: WarehousesFilters) {
    data: warehouses(filters: $filters) {
      data {
        id
        name
        streetName
        streetNumber
        phone
        company {
          id
          name
        }
        stores {
          id
          name
        }
      }
      meta {
        total
        lastPage
      }
    }
  }
`;

export const GET_WAREHOUSE = gql`
  query warehouse($id: Int!) {
    data: warehouse(id: $id) {
      id
      name
      streetName
      streetNumber
      phone
      notes
      company {
        id
        name
      }
      stores {
        id
        name
      }
    }
  }
`;

export const CREATE_WAREHOUSE = gql`
  mutation createWarehouse($input: CreateWarehouseInput!) {
    data: createWarehouse(input: $input) {
      id
    }
  }
`;

export const UPDATE_WAREHOUSE = gql`
  mutation updateWarehouse($id: Int!, $input: UpdateWarehouseInput!) {
    data: updateWarehouse(id: $id, input: $input) {
      id
    }
  }
`;
