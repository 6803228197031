import { forwardRef, useImperativeHandle, useRef } from "react";
import {
  CButton,
  CCol,
  CForm,
  CRow,
  CFormInput,
  CFormLabel,
} from "@coreui/react-pro";
import { useMutation } from "@apollo/client";
import { PriceListItem, PriceListUpdate } from "src/api/price-lists";
import { dateFormat } from "src/helpers/dates";
import { useFormik } from "formik";
import Api from "src/api";

const PriceListData = ({ priceListId, priceList }, ref) => {
  const buttonRef = useRef<HTMLButtonElement>(null);

  const [updateMutation, { loading }] = useMutation(
    Api.PriceLists.UPDATE_PRICE_LIST,
    {
      onCompleted: () => {
        window.location.reload();
      },
    }
  );

  useImperativeHandle(
    ref,
    () => ({
      submit: () => buttonRef.current?.click(),
    }),
    []
  );

  const formik = useFormik<PriceListUpdate>({
    initialValues: {
      name: priceList.name,
      profit: priceList.profit,
      dateFrom: priceList.dateFrom,
      dateTo: priceList.dateTo,
      items: priceList.items,
    },
    onSubmit: (body) => {
      if (!loading) {
        const data: PriceListUpdate = {
          name: body.name,
          profit: Math.abs(Number(body.profit)),
          dateFrom: new Date(body.dateFrom),
          dateTo: body.dateTo ? new Date(body.dateTo) : null,
          items: {} as any,
        };

        const items = body.items.filter((item) => item && item.dirty);

        items.forEach((value) => {
          const item: PriceListItem = {} as PriceListItem;

          if (typeof value.name !== "undefined") {
            item.name = value.name;
          }

          if (typeof value.price !== "undefined") {
            item.price = Number(value.price);
          }

          if (typeof value.barcode !== "undefined") {
            item.barcode = value.barcode;
          }

          if (item.barcode || item.name || item.price) {
            data.items[value.id] = item;
          }
        });

        if (!Array.isArray(data.items)) {
          data.items = [];
        }

        updateMutation({
          variables: {
            id: priceListId,
            input: data,
          },
        });
      }
    },
  });

  return (
    <CForm>
      <CRow className="form-row">
        <CCol md="3">
          <CFormLabel htmlFor="name">Nombre</CFormLabel>
        </CCol>
        <CCol xs="12" md="9">
          <CFormInput
            id="name"
            placeholder="Nombre"
            defaultValue={priceList.name}
            name="name"
            onChange={formik.handleChange}
          />
        </CCol>
      </CRow>
      <CRow className="form-row">
        <CCol md="3">
          <CFormLabel htmlFor="profit">Ganancia por Defecto</CFormLabel>
        </CCol>
        <CCol xs="12" md="9">
          <CFormInput
            id="profit"
            placeholder="0.00"
            defaultValue={priceList.profit}
            name="profit"
            type="number"
            min={0}
            onChange={formik.handleChange}
          />
        </CCol>
      </CRow>
      <CRow className="form-row">
        <CCol md="3">
          <CFormLabel htmlFor="dateFrom">Fecha de Inicio</CFormLabel>
        </CCol>
        <CCol xs="12" md="9">
          <CFormInput
            id="dateFrom"
            type="date"
            disabled={!priceList.editable}
            placeholder="Fecha de Inicio"
            defaultValue={
              priceList.dateFrom
                ? dateFormat(priceList.dateFrom.toString(), "yyyy-MM-dd")
                : undefined
            }
            name="dateFrom"
            onChange={formik.handleChange}
          />
        </CCol>
      </CRow>
      <CRow className="form-row">
        <CCol md="3">
          <CFormLabel htmlFor="dateTo">Fecha de Fin</CFormLabel>
        </CCol>
        <CCol xs="12" md="9">
          <CFormInput
            id="dateTo"
            type="date"
            disabled={!priceList.editable}
            placeholder="Fecha de Fin"
            defaultValue={
              priceList.dateTo
                ? dateFormat(priceList.dateTo.toString(), "yyyy-MM-dd")
                : undefined
            }
            name="dateTo"
            onChange={formik.handleChange}
          />
        </CCol>
      </CRow>
      <CButton
        color="primary"
        type="button"
        onClick={() => formik.handleSubmit()}
        ref={buttonRef}
        style={{ display: "none" }}
      />
    </CForm>
  );
};
export default forwardRef(PriceListData);
