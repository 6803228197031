import { AdminStore } from "./store";

import Audit from "./views/audits/Audit";

import Dashboard from "./views/dashboard/Dashboard";
import DashboardToday from "./views/dashboard/DashboardToday";

import BankPromotions from "./views/bank-promotions/BankPromotions";
import BankPromotion from "./views/bank-promotions/BankPromotion";
import NewBankPromotion from "./views/bank-promotions/NewBankPromotion";

import Categories from "./views/categories/Categories";
import Category from "./views/categories/Category";
import NewCategory from "./views/categories/NewCategory";
import CategoryProducts from "./views/categories/CategoryProducts";

import Customers from "./views/customers/Customers";
import Customer from "./views/customers/Customer";
import NewCustomer from "./views/customers/NewCustomer";

import Plans from "./views/plans/Plans";
import Plan from "./views/plans/Plan";
import MyPlan from "./views/plans/MyPlan";
import NewPlanInvoice from "./views/plans/NewPlanInvoice";

import TiendaNube from "./views/tienda-nube/TiendaNube";
import TiendaNubeSales from "./views/tienda-nube/TiendaNubeSales";
import TiendaNubeSale from "./views/tienda-nube/TiendaNubeSale";
import TiendaNubeAudit from "./views/tienda-nube/TiendaNubeAudit";
import TiendaNubeWebhooks from "./views/tienda-nube/TiendaNubeWebhooks";

import Groups from "./views/permissions/Groups";
import Group from "./views/permissions/Group";
import NewGroup from "./views/permissions/NewGroup";

import Companies from "./views/companies/Companies";
import Company from "./views/companies/Company";
import NewCompany from "./views/companies/NewCompany";

import Stores from "./views/stores/Stores";
import Store from "./views/stores/Store";
import NewStore from "./views/stores/NewStore";

import Registers from "./views/registers/Registers";
import Register from "./views/registers/Register";
import RegisterSession from "./views/registers/RegisterSession";
import NewRegister from "./views/registers/NewRegister";

import PriceLists from "./views/price-lists/PriceLists";
import PriceList from "./views/price-lists/PriceList";
import NewPriceList from "./views/price-lists/NewPriceList";

import Products from "./views/products/Products";
import Product from "./views/products/Product";
import NewProduct from "./views/products/NewProduct";

import Invoicing from "./views/invoicing/Invoicing";

import MercadoLibre from "./views/meli/MercadoLibre";
import MercadoLibreSale from "./views/meli/MercadoLibreSale";
import MercadoLibreSales from "./views/meli/MercadoLibreSales";
import MercadoLibreAudit from "./views/meli/MercadoLibreAudit";

import MercadoShops from "./views/meli/MercadoShops";
import MercadoShopsSale from "./views/meli/MercadoShopsSale";
import MercadoShopsSales from "./views/meli/MercadoShopsSales";

import MercadoPago from "./views/meli/MercadoPago";

import MPNewStore from "./views/meli/NewStore";

import Users from "./views/users/Users";
import User from "./views/users/User";
import NewUser from "./views/users/NewUser";

import Support from "./views/support/Support";
import SupportRequests from "./views/support/SupportRequests";

import Versions from "./views/versions/Versions";

import Sales from "./views/sales/Sales";
import Sale from "./views/sales/Sale";

import Orders from "./views/orders/Orders";
import Order from "./views/orders/Order";

import Suppliers from "./views/suppliers/Suppliers";
import Supplier from "./views/suppliers/Supplier";
import NewSupplier from "./views/suppliers/NewSupplier";
import SupplierProducts from "./views/suppliers/SupplierProducts";

import Excels from "./views/reports/Excels";
import Reports from "./views/reports/Reports";

import Sectors from "./views/sectors/Sectors";
import NewSector from "./views/sectors/NewSector";
import Sector from "./views/sectors/Sector";

import Settings from "./views/settings/Settings";

import Stock from "./views/stock/Stock";
import StockAdd from "./views/stock/StockAdd";
import StockMove from "./views/stock/StockMove";

import Warehouses from "./views/warehouses/Warehouses";
import Warehouse from "./views/warehouses/Warehouse";
import NewWarehouse from "./views/warehouses/NewWarehouse";
import StockRemove from "./views/stock/StockRemove";
import Methods from "./views/registers/Methods";
import NewMethod from "./views/registers/NewMethod";

const { user } = AdminStore.getState();

const routes = [
  { path: "/", name: "Admin" },
  { path: "/audit", name: "Auditoria", component: Audit },
  { path: "/home", name: "Hoy", component: DashboardToday },
  { path: "/dashboard", name: "Dashboard", component: Dashboard },
  {
    path: "/categories/:id/products",
    name: "Productos de Categoría",
    component: CategoryProducts,
  },
  {
    path: "/categories/:id",
    name: "Categoría",
    component: Category,
  },
  {
    path: "/categories/new",
    name: "Nueva Categoría",
    component: NewCategory,
  },
  { path: "/categories", name: "Categorías", component: Categories },
  { path: "/customers/new", name: "Nuevo Cliente", component: NewCustomer },
  { path: "/customers/:id", name: "Cliente", component: Customer },
  { path: "/customers", name: "Clientes", component: Customers },
  {
    path: "/suppliers/:id/products",
    name: "Productos de Proveedor",
    component: SupplierProducts,
  },
  {
    path: "/suppliers/:id",
    name: "Proveedor",
    component: Supplier,
  },
  { path: "/suppliers/new", name: "Nuevo Proveedor", component: NewSupplier },
  { path: "/suppliers", name: "Proveedores", component: Suppliers },
  { path: "/stock/add", name: "Ingresos", component: StockAdd },
  { path: "/stock/remove", name: "Egresos", component: StockRemove },
  { path: "/stock/move", name: "Mover Stock", component: StockMove },
  { path: "/stock", name: "Stock", component: Stock },
  {
    path: "/companies",
    name: "Empresas",
    component: Companies,
  },
  {
    path: "/companies/new",
    name: "Nueva Empresa",
    component: NewCompany,
  },
  {
    path: "/stores/new",
    name: "Nueva Tienda",
    component: NewStore,
  },
  {
    path: "/stores/:id",
    name: "Tienda",
    component: Store,
  },
  {
    path: "/invoicing",
    name: "Factura Electrónica",
    component: Invoicing,
  },
  {
    path: "/registers",
    name: "Cajas Registradoras",
    component: Registers,
  },
  {
    path: "/registers/methods/new",
    name: "Nuevo Método de Ingreso / Egreso",
    component: NewMethod,
  },
  {
    path: "/registers/methods",
    name: "Métodos de Ingreso / Egreso",
    component: Methods,
  },
  {
    path: "/registers/new",
    name: "Nueva Caja Registradora",
    component: NewRegister,
  },
  {
    path: "/registers/:id",
    name: "Caja Registradora",
    component: Register,
  },
  {
    path: "/registers/:registerId/sessions/:id",
    name: "Transacciones",
    component: RegisterSession,
  },
  {
    path: "/companies/:id",
    name: "Empresa",
    component: Company,
  },
  {
    path: "/excels",
    name: "Excel's",
    component: Excels,
  },
  {
    path: "/groups",
    name: "Grupos de Permisos",
    component: Groups,
  },
  {
    path: "/groups/new",
    name: "Nuevo Grupo de Permisos",
    component: NewGroup,
  },
  {
    path: "/groups/:id",
    name: "Grupo de Permisos",
    component: Group,
  },
  {
    path: "/plans",
    name: "Planes",
    component: Plans,
  },
  {
    path: "/plans/invoice/new",
    name: "Crear Factura",
    component: NewPlanInvoice,
  },
  {
    path: "/plans/:id",
    name: "Plan",
    component: Plan,
  },
  {
    path: "/plan",
    name: "Tu plan",
    component: MyPlan,
  },
  {
    path: "/mercado-libre/:storeId/products",
    name: "Auditoria de Productos",
    component: MercadoLibreAudit,
  },
  {
    path: "/mercado-libre/:storeId/sales",
    name: "Ventas de Mercado Libre",
    component: MercadoLibreSales,
  },
  {
    path: "/mercado-libre/:storeId/sales/:orderId",
    name: "Venta de Mercado Libre",
    component: MercadoLibreSale,
  },
  {
    path: "/mercado-libre",
    name: "Ventas de Mercado Libre",
    component: MercadoLibre,
  },
  {
    path: "/mercado-shops/:storeId/sales",
    name: "Ventas de Mercado Shops",
    component: MercadoShopsSales,
  },
  {
    path: "/mercado-shops/:storeId/sales/:orderId",
    name: "Venta de Mercado Shops",
    component: MercadoShopsSale,
  },
  {
    path: "/mercado-shops",
    name: "Mercado Shops",
    component: MercadoShops,
  },
  {
    path: "/mercado-pago",
    name: "Mercado Pago",
    component: MercadoPago,
  },
  {
    path: "/mercado-pago/stores/new",
    name: "Mercado Pago - Nueva Tienda",
    component: MPNewStore,
  },
  {
    path: "/price-lists",
    name: "Listas de Precios",
    component: PriceLists,
  },
  {
    path: "/price-lists/new",
    name: "Nueva Lista de Precios",
    component: NewPriceList,
  },
  {
    path: "/price-lists/:id",
    name: "Detalles de la Lista de Precios",
    component: PriceList,
  },
  { path: "/products", name: "Productos", component: Products },
  {
    path: "/products/new",
    name: "Nuevo Producto",
    component: NewProduct,
  },
  {
    path: "/products/:id",
    name: "Detalles del Producto",
    component: Product,
  },
  {
    path: "/sales",
    name: "Ventas",
    component: Sales,
  },
  {
    path: "/sales/:id",
    name: "Venta",
    component: Sale,
  },
  {
    path: "/settings",
    name: "Configuraciones",
    component: Settings,
  },
  {
    path: "/banks/new",
    name: "Crear Promoción Bancaria",
    component: NewBankPromotion,
  },
  {
    path: "/banks/:id",
    name: "Promoción Bancaria",
    component: BankPromotion,
  },
  {
    path: "/banks",
    name: "Promociónes Bancarias",
    component: BankPromotions,
  },
  {
    path: "/stores",
    name: "Puntos de Venta",
    component: Stores,
  },
  {
    path: "/tienda-nube/:storeId/webhooks",
    name: "Webhooks",
    component: TiendaNubeWebhooks,
  },
  {
    path: "/tienda-nube/:storeId/products",
    name: "Auditoria de Productos",
    component: TiendaNubeAudit,
  },
  {
    path: "/tienda-nube/:storeId/sales/:orderId",
    name: "Venta de Tienda Nube",
    component: TiendaNubeSale,
  },
  {
    path: "/tienda-nube/:storeId/sales",
    name: "Ventas en Tienda Nube",
    component: TiendaNubeSales,
  },
  { path: "/tienda-nube", name: "Tienda Nube", component: TiendaNube },
  {
    path: "/orders",
    name: "Pedidos",
    component: Orders,
  },
  {
    path: "/orders/:id",
    name: "Pedido",
    component: Order,
  },
  {
    path: "/reports",
    name: "Reportes",
    component: Reports,
  },
  {
    path: "/sectors",
    name: "Rubros",
    component: Sectors,
  },
  {
    path: "/sectors/new",
    name: "Nuevo Rubro",
    component: NewSector,
  },
  {
    path: "/sectors/:id",
    name: "Rubro",
    component: Sector,
  },
  {
    path: "/users",
    name: user?.isAdmin ? "Usuarios" : "Empleados",
    component: Users,
  },
  {
    path: "/users/new",
    name: user?.isAdmin ? "Nuevo Usuario" : "Nuevo Empleado",
    component: NewUser,
  },
  {
    path: "/users/:id",
    name: user?.isAdmin ? "Detalles del Usuario" : "Detalles del Empleado",
    component: User,
  },
  { path: "/warehouses/new", name: "Nuevo Depósito", component: NewWarehouse },
  { path: "/warehouses/:id", name: "Depósito", component: Warehouse },
  { path: "/warehouses", name: "Depósitos", component: Warehouses },
  {
    path: "/versions",
    name: "Versiones",
    component: Versions,
  },
  {
    path: "/support/:id",
    name: "Incidencia",
    component: Support,
  },
  {
    path: "/support",
    name: "Incidencias",
    component: SupportRequests,
  },
  {
    path: "/me",
    name: "Mi perfil",
    component: User,
  },
];

export default routes;
