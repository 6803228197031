import { useQuery } from "@apollo/client";
import {
  CButton,
  CCol,
  CDateRangePicker,
  CFormLabel,
  CRow,
} from "@coreui/react-pro";
import { useFormik } from "formik";
import Api from "src/api";
import { Store, StoreType } from "src/api/stores";
import { logEvent } from "src/helpers/analytics";
import {
  dateFormat,
  defaultDateFormat,
  toArgentinaTime,
} from "src/helpers/dates";
import { GraphQLMeta } from "src/types";
import SmartSelect from "../SmartSelect";
import { sortBy } from "lodash";
import { isAfter } from "date-fns/isAfter";
import { startOfWeek } from "date-fns/startOfWeek";
import { startOfMonth } from "date-fns/startOfMonth";
import { startOfDay } from "date-fns/startOfDay";
import { endOfDay } from "date-fns/endOfDay";
import { parse } from "date-fns/parse";
import { es } from "date-fns/locale";
import { format } from "date-fns/format";

const RangeAndStorePicker = ({
  refetch,
  maxDate,
  hideRangePicker = false,
  eventTitle,
  defaultDateFrom,
  defaultDateTo,
  defaultLabel,
}: {
  eventTitle: string;
  hideRangePicker?: boolean;
  maxDate?: Date;
  refetch: (variables: any) => void;
  defaultDateFrom?: string;
  defaultDateTo?: string;
  defaultLabel?: string;
}) => {
  const { data: stores } = useQuery<GraphQLMeta<Store>>(
    Api.Stores.LIST_STORES,
    {
      variables: {
        filters: {
          permission: "LIST_SALES",
          type: StoreType.Store,
          limit: 0,
        },
      },
      onCompleted: ({ data }) => {
        if (data.data.length === 1) {
          formik.setFieldValue("storeId", data.data[0].id);
          formik.handleSubmit();
        }
      },
    }
  );

  const formik = useFormik<{
    dateFrom: Date;
    dateTo: Date;
    storeId: number;
    storeName: string;
  }>({
    initialValues: {
      dateFrom: defaultDateFrom ? new Date(defaultDateFrom) : new Date(),
      dateTo: defaultDateTo ? new Date(defaultDateTo) : new Date(),
      storeId: 0,
      storeName: "",
    },
    onSubmit: (data) => {
      let dateFrom = dateFormat(
        data.dateFrom,
        defaultDateFormat,
        toArgentinaTime
      );
      const dateTo = data.dateTo ? dateFormat(data.dateTo) : "";
      const storeName =
        data.storeId > 0
          ? stores?.data.data.find((s) => s.id === data.storeId)?.name ?? ""
          : "";

      if (
        isAfter(
          new Date(dateFrom),
          dateTo !== "" ? new Date(dateTo) : new Date()
        )
      ) {
        dateFrom = dateFormat(
          dateTo === "" ? new Date() : new Date(dateTo),
          defaultDateFormat,
          toArgentinaTime
        );
      }

      logEvent(eventTitle, {
        filters: {
          dateFrom,
          dateTo,
          storeId: data.storeId > 0 ? data.storeId : undefined,
          storeName: data.storeId > 0 ? storeName : undefined,
        },
      });

      refetch({
        variables: {
          dateFrom: hideRangePicker ? dateFormat(new Date()) : dateFrom,
          dateTo: hideRangePicker ? dateFormat(new Date()) : dateTo,
          storeId: data.storeId > 0 ? data.storeId : undefined,
          storeName: data.storeId > 0 ? storeName : undefined,
        },
      });
    },
  });

  return (
    <CRow className="pr-0">
      {!hideRangePicker && (
        <CCol sm={hideRangePicker ? "5" : "4"}>
          <CDateRangePicker
            startDate={formik.values.dateFrom}
            endDate={formik.values.dateTo}
            label="Rango de Fechas"
            locale="es"
            inputDateParse={(date) =>
              parse(date.toString(), "dd/MM/yyyy", new Date(), { locale: es })
            }
            inputDateFormat={(date) => format(new Date(date), "dd/MM/yyyy")}
            id="datepicker"
            maxDate={maxDate ?? new Date()}
            ranges={{
              Hoy: [new Date(), new Date()],
              "Esta semana": [
                startOfWeek(new Date(), { weekStartsOn: 1 }),
                new Date(new Date()),
              ],
              "Este mes": [startOfMonth(new Date()), new Date(new Date())],
            }}
            onStartDateChange={(date) => {
              logEvent("dashboard.filters.set-date-from", {
                date,
              });

              if (date) {
                formik.setFieldValue("dateFrom", startOfDay(new Date(date)));
              } else {
                formik.setFieldValue("dateFrom", "");
              }
            }}
            onEndDateChange={(date) => {
              logEvent("dashboard.filters.set-date-to", {
                date,
              });

              if (date) {
                formik.setFieldValue("dateTo", endOfDay(new Date(date)));
              } else {
                formik.setFieldValue("dateTo", "");
              }
            }}
            footer
            todayButton={false}
            cancelButtonColor="secondary"
            cancelButton="Cancelar"
            confirmButtonColor="ghost"
            confirmButtonSize="sm"
            confirmButtonVariant="ghost"
            confirmButton={
              <CButton
                size="sm"
                color="primary"
                onClick={() => formik.handleSubmit()}
              >
                Buscar
              </CButton>
            }
            cleaner={false}
          />
        </CCol>
      )}

      {(stores?.data.data.length ?? 0) > 1 && (
        <>
          <CCol sm={hideRangePicker ? "5" : "3"}>
            <CFormLabel>Punto de Venta</CFormLabel>
            <SmartSelect
              name="storeId"
              search
              options={[
                { value: 0, name: defaultLabel ?? "Todos" },
                ...(sortBy(stores?.data.data ?? [], "name").map((store) => ({
                  name: store.name,
                  value: store.id,
                })) ?? []),
              ]}
              resultsCount={10}
              placeholder="Selecciona Punto de Venta"
              onChange={(e) => {
                logEvent("dashboard.filters.set-store", {
                  storeId: e,
                });

                formik.setFieldValue("storeId", Number(e));
                formik.handleSubmit();
              }}
              value={formik.values.storeId.toString()}
            />
          </CCol>
        </>
      )}
    </CRow>
  );
};

export default RangeAndStorePicker;
