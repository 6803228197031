import { useQuery } from "@apollo/client";
import {
  CButton,
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CSmartTable,
  CRow,
} from "@coreui/react-pro";
import { Item } from "@coreui/react-pro/dist/esm/components/smart-table/types";
import { Link, useNavigate } from "react-router-dom";
import Api from "src/api";
import { Category } from "src/api/categories";
import Icon from "src/components/Icon";
import Pagination from "src/components/Pagination/Pagination";
import PlanAlert from "src/containers/PlanAlert";
import { logEvent } from "src/helpers/analytics";
import { usePagination } from "src/hooks/pagination";
import { useAdminStore } from "src/store";
import { GraphQLMeta } from "src/types";

const CategoriesScreen = () => {
  const navigate = useNavigate();
  const { user } = useAdminStore();
  const { page, pageChange } = usePagination("categories");
  const { data: categories, loading } = useQuery<GraphQLMeta<Category>>(
    Api.Categories.LIST_CATEGORIES,
    {
      fetchPolicy: "no-cache",
      variables: {
        filters: {
          page,
        },
      },
    }
  );

  const fields = [
    { key: "id", label: "ID" },
    { key: "name", label: "Nombre", className: "font-weight-bold" },
    { key: "identifier", label: "Identificador" },
    { key: "count", label: "# de Productos" },
    { key: "notes", label: "Notas" },
    { key: "actions", label: "Acciones", _props: { className: "text-right" } },
  ];

  if (user?.isAdmin) {
    fields.push({ key: "company", label: "Empresa" });
  }

  return (
    <>
      <PlanAlert />
      <CRow>
        <CCol xl={12}>
          <CCard>
            <CCardHeader>
              <CRow className="align-items-center justify-content-center">
                <CCol sm={9} xs="6" className="px-0">
                  Categorías
                </CCol>
                <CCol sm={3} xs="6" className="row justify-content-end">
                  <Link
                    to="/categories/new"
                    className="flex justify-content-end p-0"
                  >
                    <CButton size="sm" color="primary">
                      Crear Categoría
                    </CButton>
                  </Link>
                </CCol>
              </CRow>
            </CCardHeader>
            <CCardBody>
              <CSmartTable
                itemsPerPage={20}
                loading={loading}
                items={categories?.data.data || []}
                columns={fields}
                tableProps={{
                  striped: true,
                }}
                scopedColumns={{
                  company: (item: Category) => <td>{item.company.name}</td>,
                  notes: (item: Category) => <td>{item.notes}</td>,
                  count: (item: Category) => {
                    const productsCount = item.products.length;

                    return (
                      <td>
                        {productsCount} producto
                        {productsCount === 0 || productsCount > 1 ? "s" : ""}
                      </td>
                    );
                  },
                  actions: (item: Category) => (
                    <td className="text-right">
                      <CButton
                        size="sm"
                        color="info"
                        onClick={(e) => {
                          navigate(`/categories/${item.id}`);

                          e.stopPropagation();
                        }}
                      >
                        <Icon name="pencil" />
                      </CButton>
                    </td>
                  ),
                }}
                clickableRows
                onRowClick={(item: Item) => {
                  logEvent("categories.navigate", {
                    date: item,
                  });

                  navigate(`/categories/${item.id}/products`);
                }}
              />

              <Pagination
                meta={categories}
                page={page}
                pageChange={pageChange}
              />
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
    </>
  );
};

export default CategoriesScreen;
