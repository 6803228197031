import FeatureAlert from "src/containers/FeatureAlert";
import SalesReport from "./components/SalesReport";
import { useAdminStore } from "src/store";
import { isDebtor } from "src/helpers/permissions";

const Excels = () => {
  const { currentCompany } = useAdminStore();

  return (
    <>
      <FeatureAlert />

      {!isDebtor(currentCompany) && <SalesReport />}
    </>
  );
};

export default Excels;
