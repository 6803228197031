import axios, {
  AxiosRequestHeaders,
  AxiosResponse,
  InternalAxiosRequestConfig,
} from "axios";
import Config from "src/config";
import { storeConfig } from "src/store";
import { AppVersion } from "src/types";

const publicEndpoints = ["/login"];

const axiosClient = axios.create({
  baseURL: Config.apiUrl,
  headers: {
    "Content-Type": "application/json",
    "x-daruma-app-version": AppVersion,
    "x-daruma-service": "ADMIN",
  },
});

const requestInterceptor = (config: InternalAxiosRequestConfig<any>) => {
  if (!config.headers) {
    config.headers = {} as AxiosRequestHeaders;
  }

  if (config.url && !publicEndpoints.includes(config.url)) {
    const storage = localStorage.getItem(storeConfig.name);
    const user = storage ? JSON.parse(storage).state.user : {};
    const token = user ? user.accessToken : "";

    config.headers["Authorization"] = `Bearer ${token}`;
    config.headers["x-daruma-app-version"] = AppVersion;
    config.headers["x-daruma-service"] = "ADMIN";
  }

  config.headers["x-daruma-app-version"] = AppVersion;
  config.headers["x-daruma-service"] = "ADMIN";

  return config;
};

const responseInterceptor = (response: AxiosResponse<any, any>) => {
  if (response.headers["content-type"]?.includes("text/csv")) {
    return {
      data: response.data,
      fileName: response.headers["content-filename"],
    };
  }

  if (response.headers["content-type"]?.includes("application/pdf")) {
    return {
      data: response.data,
      fileName: response.headers["content-filename"],
    };
  }

  if (response.data.meta) {
    return {
      data: response.data.data,
      meta: response.data.meta,
    };
  }

  return response.data.data;
};

axiosClient.interceptors.request.use((config) => requestInterceptor(config));

axiosClient.interceptors.response.use(
  (response) => responseInterceptor(response),
  (err) => {
    throw err.response.data;
  }
);

export default axiosClient;
