import {
  CButton,
  CCard,
  CCardBody,
  CCardHeader,
  CForm,
  CSmartTable,
} from "@coreui/react-pro";
import { AfipStatus, parseAfipStatus } from "src/api/afip";
import AfipConnected from "./AfipConnected";
import { Store, StoreType } from "src/api/stores";
import { Fragment, useState } from "react";
import NotConnectedStatus from "./NotConnectedStatus";

const Franchise = ({ connection }: { connection: AfipStatus[] }) => {
  const [currentStore, setCurrentStore] = useState<Store | null>(null);

  if (currentStore) {
    return (
      <NotConnectedStatus
        companyId={currentStore.company.id}
        storeId={currentStore.id}
      />
    );
  }

  const actions: { key: string; label: string }[] = [];

  if (
    connection.some(
      (currentConnection) => currentConnection.status === "NOT_CONNECTED"
    )
  ) {
    actions.push({
      key: "actions",
      label: "Acciones",
    });
  }

  return (
    <>
      <CCard>
        <CCardBody>
          <CForm>
            <CSmartTable
              items={connection}
              columns={[
                {
                  key: "id",
                  label: "ID",
                },
                {
                  key: "name",
                  label: "Nombre",
                },
                {
                  key: "invoiceTypes",
                  label: "Tipos de Factura",
                },
                {
                  key: "status",
                  label: "Estado",
                },
                ...actions,
              ]}
              scopedColumns={{
                id: (item: AfipStatus) => (
                  <td>
                    <span>{item.store.id}</span>
                  </td>
                ),
                name: (item: AfipStatus) => (
                  <td>
                    <span>{item.store.name}</span>
                  </td>
                ),
                status: (item: AfipStatus) => (
                  <td>
                    <span>{parseAfipStatus(item)}</span>
                  </td>
                ),
                actions: (item: AfipStatus) => (
                  <td>
                    {item.status === "NOT_CONNECTED" && (
                      <CButton
                        size="sm"
                        color="primary"
                        onClick={() =>
                          setCurrentStore({
                            ...item.store,
                            company: item.company,
                          })
                        }
                      >
                        Conectar
                      </CButton>
                    )}
                  </td>
                ),
                invoiceTypes: (item: AfipStatus) => (
                  <td>
                    {item.store.config.invoiceTypes.length === 0 ? (
                      <>Ninguna</>
                    ) : (
                      <>
                        {item.store.config.invoiceTypes
                          .map(({ name }) => name)
                          .join(", ")}
                      </>
                    )}
                  </td>
                ),
              }}
              tableProps={{
                striped: true,
              }}
            />
          </CForm>
        </CCardBody>
      </CCard>

      <CCard className="mt-3">
        <CCardHeader>Configuraciones de AFIP</CCardHeader>
        <CCardBody>
          {connection
            .filter(
              (currentConnection) =>
                currentConnection.status === "CONNECTED" &&
                currentConnection.store.type === StoreType.Store
            )
            .map((currentConnection, index) => (
              <Fragment key={currentConnection.store.id}>
                {connection.length > 1 && index > 0 && <hr />}
                <AfipConnected
                  type={StoreType.Store}
                  companyId={currentConnection.company.id}
                  storeId={currentConnection.store.id}
                />
              </Fragment>
            ))}
        </CCardBody>
      </CCard>
    </>
  );
};

export default Franchise;
