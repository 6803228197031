import { Sale } from "src/api/sales";

export const getCustomer = (
  customer?: Sale["customer"],
  lightMode: boolean = false
) => {
  if (!customer) {
    return "Consumidor final";
  }

  const data: string[] = [];

  if (customer.id) {
    data.push(`#${customer.id} - `);
  }

  if (customer.name) {
    data.push(customer.name);
  }

  if (customer.lastname) {
    data.push(customer.lastname);
  }

  if (customer.utid && !lightMode) {
    const cuit =
      customer.utid.length > 8
        ? [
            customer.utid.slice(0, 2),
            customer.utid.slice(2, -1),
            customer.utid.slice(-1),
          ].join("-")
        : customer.utid;

    data.push(`| ${cuit}`);
  }

  if (customer.alias && !lightMode) {
    data.push(`| Alias: ${customer.alias}`);
  }

  return data.map((value) => value?.trim()).join(" ");
};
