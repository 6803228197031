import { useMutation } from "@apollo/client";
import {
  CButton,
  CCol,
  CForm,
  CLoadingButton,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CRow,
} from "@coreui/react-pro";
import { useFormik } from "formik";
import {
  ForwardRefRenderFunction,
  forwardRef,
  useCallback,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import Api from "src/api";
import { SaleInvoice } from "src/api/sales";
import Toast, { ForwardedToastProps } from "src/components/Toast";
import { logEvent } from "src/helpers/analytics";
import { ForwardedModalProps } from "src/types";

const NOTES = [
  {
    invoiceTypeId: 1,
    value: 3,
    label: "Nota de Crédito A",
  },
  {
    invoiceTypeId: 2,
    value: 8,
    label: "Nota de Crédito B",
  },
  {
    invoiceTypeId: 11,
    value: 13,
    label: "Nota de Crédito C",
  },
];

type CreditNoteForm = {
  saleId: number;
  invoiceTypeId: number;
  type: "SaleInvoice";
  customer: {
    id: number;
    dniType: number;
    dni: string;
  } | null;
  previousInvoice: {
    invoiceId: number;
    invoiceTypeId: number;
    posId: number;
    relatedTo: number;
  } | null;
};

const CreditNoteModal: ForwardRefRenderFunction<ForwardedModalProps, {}> = (
  _,
  ref
) => {
  const [visible, setVisible] = useState<boolean>(false);
  const toastRef = useRef<ForwardedToastProps>(null);

  const [mutation, { loading }] = useMutation(Api.Afip.CREATE_SALE_INVOICE, {
    onCompleted: () => {
      toastRef.current?.show();

      setTimeout(() => {
        handleDismiss();
        window.location.reload();
      }, 1200);
    },
  });

  const formik = useFormik<CreditNoteForm>({
    initialValues: {
      saleId: 0,
      invoiceTypeId: 0,
      previousInvoice: null,
      type: "SaleInvoice",
      customer: null,
    },
    validateOnChange: true,
    onSubmit: (values) => {
      if (!loading) {
        logEvent("sale.create-credit-note", {
          values,
        });

        mutation({
          variables: {
            input: {
              saleId: values.saleId,
              invoiceTypeId: Number(values.invoiceTypeId),
              previousInvoice: values.previousInvoice,
              customer: values.customer,
              force: true,
            },
          },
        });
      }
    },
  });

  const handleDismiss = useCallback(() => {
    setVisible(false);
    formik.resetForm();
  }, [formik]);

  useImperativeHandle(
    ref,
    () => ({
      close: () => {
        handleDismiss();
      },
      open: (s: SaleInvoice) => {
        setVisible(true);

        formik.setValues({
          saleId: s.saleId,
          invoiceTypeId:
            NOTES.find((v) => v.invoiceTypeId === s.invoiceTypeId)?.value ?? 0,
          customer: {
            id: 0,
            dniType: s.afipInvoice.document.id,
            dni: s.afipInvoice.dniNumber.toString(),
          },
          previousInvoice: {
            invoiceId: s.invoiceId,
            invoiceTypeId: s.invoiceTypeId,
            posId: s.posId,
            relatedTo: s.id,
          },
          type: "SaleInvoice",
        });
      },
    }),
    [formik, handleDismiss]
  );

  return (
    <CModal alignment="center" visible={visible} onClose={handleDismiss}>
      <CModalHeader closeButton>Crear Nota de Crédito</CModalHeader>
      <CModalBody>
        <CForm>
          <CRow>
            <CCol>
              ¿Estás seguro que queres crear una nota de crédito para esta
              factura?
            </CCol>
          </CRow>
        </CForm>
      </CModalBody>
      <CModalFooter>
        <CButton size="sm" color="secondary" onClick={() => handleDismiss()}>
          Cancelar
        </CButton>
        <CLoadingButton
          size="sm"
          loading={loading}
          disabled={loading || !formik.isValid}
          color="success"
          onClick={() => formik.handleSubmit()}
        >
          Crear Nota de Crédito
        </CLoadingButton>
      </CModalFooter>

      <Toast ref={toastRef} text="Nota de crédito creada correctamente" />
    </CModal>
  );
};

export default forwardRef(CreditNoteModal);
