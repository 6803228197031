import {
  ForwardRefRenderFunction,
  forwardRef,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import {
  CButton,
  CModal,
  CModalHeader,
  CModalFooter,
  CModalBody,
} from "@coreui/react-pro";
import { ForwardedModalProps } from "src/types";
import { Product } from "src/api/products";

const ConfirmDialog: ForwardRefRenderFunction<
  ForwardedModalProps,
  { onSubmit?: () => void }
> = ({ onSubmit }, ref) => {
  const successButtonRef = useRef<HTMLButtonElement>(null);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [product, setProduct] = useState<Product>();

  useImperativeHandle(
    ref,
    () => ({
      open: (prod: Product) => {
        setProduct(prod);
        setShowModal(true);
      },
      close: () => {
        setProduct(undefined);
        setShowModal(false);
      },
    }),
    []
  );

  return (
    <CModal
      alignment="center"
      size="lg"
      backdrop="static"
      visible={showModal}
      onClose={() => setShowModal(false)}
      onShow={() => {
        setTimeout(() => {
          successButtonRef.current?.focus();
        }, 300);
      }}
    >
      <CModalHeader closeButton={false}>Creación de Producto</CModalHeader>
      <CModalBody>
        Ya hay un producto con el mismo nombre y/o codigo de barra existente en
        tu lista de productos, si prosigues, vas a estar actualizando ese
        producto en lugar de crear uno nuevo.
        <br />
        <br />
        <a href={`/#/products/${product?.id}`} target="_blank" rel="noreferrer">
          Ver producto
        </a>
        <br />
        <br />
        ¿Estas seguro que quieres seguir?
      </CModalBody>
      <CModalFooter>
        <CButton
          size="sm"
          type="button"
          color="secondary"
          onClick={() => {
            setShowModal(false);
            setProduct(undefined);
          }}
        >
          Cancelar
        </CButton>
        <CButton
          size="sm"
          ref={successButtonRef}
          onClick={() => onSubmit?.()}
          color="success"
        >
          Continuar
        </CButton>
      </CModalFooter>
    </CModal>
  );
};

export default forwardRef(ConfirmDialog);
