import { useQuery } from "@apollo/client";
import { CAlert, CRow } from "@coreui/react-pro";
import Api from "src/api";
import { CompanyPaymentStatus } from "src/api/companies";
import { isDebtor } from "src/helpers/permissions";
import { useAdminStore } from "src/store";
import { ToPayLink } from "./FeatureAlert";

const PlanAlert = () => {
  const { currentCompany } = useAdminStore();

  const { data: company } = useQuery(Api.Companies.GET_COMPANY, {
    skip: !currentCompany?.id,
    fetchPolicy: "no-cache",
    variables: {
      id: currentCompany?.id,
    },
  });

  if (!company?.data) {
    return null;
  }

  const shouldDisplay = [
    CompanyPaymentStatus.Debtor,
    CompanyPaymentStatus.Unpaid,
    CompanyPaymentStatus.Warning,
  ].includes(company.data.paymentStatus);

  if (!shouldDisplay) {
    return null;
  }

  return (
    <CRow className="mx-0 mb-3">
      <CAlert
        color={
          company.data.paymentStatus === CompanyPaymentStatus.Warning
            ? "warning"
            : "danger"
        }
        className="mb-0"
      >
        {company.data.paymentStatus === CompanyPaymentStatus.Warning && (
          <>
            ¡Hola! Esperamos que estés disfrutando de todas las funcionalidades
            que te ofrece nuestro sistema. <br />
            Solo queremos recordarte que tu plan está por vencer en pocos días.
            Para que no pierdas acceso a todo lo que te ofrece, te sugerimos que
            regularices tu situación. <ToPayLink />
            <br />
            ¡Gracias por confiar en nosotros!
          </>
        )}

        {company.data.paymentStatus === CompanyPaymentStatus.Unpaid && (
          <>
            ¡Hola! Esperamos que estés disfrutando de todas las funcionalidades
            que te ofrece nuestro sistema. <br />
            Solo queremos recordarte que tu plan está vencido, por lo que
            algunas funcionalides estaran limitadas y no podras usarlas. Para
            recuperarlas y que no pierdas acceso a todo lo que te ofrecemos, te
            sugerimos que regularices tu situación. <ToPayLink />
            <br /> ¡Gracias por confiar en nosotros!
          </>
        )}

        {isDebtor(company.data) && (
          <>
            ¡Hola! Esperamos que estés disfrutando de todas las funcionalidades
            que te ofrece nuestro sistema. <br />
            Solo queremos recordarte que tu plan está vencido, y el impago
            genera recargos, tambien es probable que algunas funcionalides esten
            limitadas. Para que no pierdas acceso a todo lo que te ofrecemos, te
            sugerimos que regularices tu situación. <ToPayLink />
            <br /> ¡Gracias por confiar en nosotros!
          </>
        )}
        {/* pagando en el{" "}
        <CAlertLink href={currentPlan.nextPlan?.paymentLink} target="_blank">
          siguiente link
        </CAlertLink>
        */}
      </CAlert>
    </CRow>
  );
};

export default PlanAlert;
