import { gql } from "@apollo/client";
import { User } from "./users";
import { Warehouse } from "./warehouses";
import { Product } from "./products";

export type RetireItem = {
  id: number;
  quantity: number;
  product: Pick<Product, "id" | "name" | "price" | "barcode">;
  reason: string;
  user: Pick<User, "id" | "name" | "lastname">;
};

export enum RetireType {
  Other = "OTHER",
  Sale = "SALE",
  Move = "MOVE",
}

export type Retire = {
  id: number;
  status: "OPEN" | "FULFILLED" | "CLOSED";
  user: Pick<User, "id" | "name" | "lastname">;
  warehouse: Warehouse;
  toWarehouse?: Warehouse;
  items: RetireItem[];
  type: RetireType;
  notes: string;
  warehouseId?: number;

  createdAt: Date;
  updatedAt: Date;
};

export const LIST_RETIRES = gql`
  query retires($filters: RetiresFilters!) {
    data: retires(filters: $filters) {
      data {
        id
        status
        type
        notes

        user {
          id
          name
          lastname
        }

        warehouse {
          id
          name
        }

        toWarehouse {
          id
          name
        }

        createdAt
        updatedAt
      }
      meta {
        total
        lastPage
      }
    }
  }
`;

export const GET_RETIRE = gql`
  query retire($id: Int!) {
    data: retire(id: $id) {
      id
      status
      type
      notes
      warehouseId

      toWarehouse {
        id
        name
      }

      user {
        id
        name
        lastname
      }

      warehouse {
        id
        name
        companyId

        stores {
          id
        }
      }

      items {
        id
        quantity

        product {
          id
          name
          barcode
          price
        }

        user {
          id
          name
          lastname
        }
      }

      createdAt
      updatedAt
    }
  }
`;

export const CREATE_RETIRE = gql`
  mutation createRetire($input: CreateRetireInput!) {
    data: createRetire(input: $input) {
      id
    }
  }
`;

export const ADD_ITEM_TO_RETIRE = gql`
  mutation addItemToRetire($input: AddItemToRetireInput!) {
    data: addItemToRetire(input: $input) {
      id
    }
  }
`;

export const DELETE_ITEM_FROM_RETIRE = gql`
  mutation deleteItemFromRetire($id: Int!) {
    data: deleteItemFromRetire(id: $id) {
      id
    }
  }
`;

export const UPDATE_RETIRE = gql`
  mutation updateRetire($id: Int!, $input: UpdateRetireInput!) {
    data: updateRetire(id: $id, input: $input) {
      id
      type
    }
  }
`;

export const CLOSE_RETIRE = gql`
  mutation closeRetire($id: Int!, $warehouseId: Int) {
    data: closeRetire(id: $id, warehouseId: $warehouseId) {
      id
    }
  }
`;

export const FULFILL_RETIRE = gql`
  mutation fulfillRetire($id: Int!) {
    data: fulfillRetire(id: $id) {
      id
      productsToUpdate
    }
  }
`;

export const FULFILL_RETIRE_MOVE = gql`
  mutation fulfillRetireMove($id: Int!, $warehouseId: Int!) {
    data: fulfillRetireMove(id: $id, warehouseId: $warehouseId) {
      id
    }
  }
`;
